<template>
	<FormPostcodeAdditionalFee
		:is-submitting="isCreating"
		@onSubmit="handleSubmit"
	/>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import FormPostcodeAdditionalFee from '@/components/FormPostcodeAdditionalFee.vue';
import { priceToAPI } from '../assets/js/helpers';
import { SHIPPING_ADDITIONAL_FEE_TYPE } from '../enums/shippings';

export default {
	name: 'ShippingPostcodeAdditionalFeeCreate',
	components: {
		FormPostcodeAdditionalFee,
	},
	computed: {
		...mapState('additionalFees', {
			isCreating: (state) => state.additionalFees[SHIPPING_ADDITIONAL_FEE_TYPE.POSTCODE].create.isCreating,
		}),
	},
	methods: {
		...mapActions({
			postAdditionalFee: 'additionalFees/postAdditionalFee',
		}),

		async handleSubmit(fee, postcodeList) {
			const shippingMethodId = this.$route.params.shippingMethodId;
			const params = {
				fee: priceToAPI(fee),
				type: SHIPPING_ADDITIONAL_FEE_TYPE.POSTCODE,
				item_ids: postcodeList,
			};

			try {
				await this.postAdditionalFee({
					id: shippingMethodId,
					type: SHIPPING_ADDITIONAL_FEE_TYPE.POSTCODE,
					params,
				});

				this.$router.push({ name: 'ShippingSetting' });
			} catch {
				// Do nothing
			}
		},
	},
};
</script>